import axios from 'axios';
import { useEffect, useState } from 'react';

function Test() {
    //const [text, se]
    const [text, setText] = useState('');
    async function test() {
        var res = await axios.get('/');
        const { data } = res;
        setText(data.statusText);
        console.log(data);
    };
    console.log(text);


    return (
        <>
            <p>{text}</p>
        </>
    )
}

export default Test