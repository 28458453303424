import Button from 'react-bootstrap/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import { Stack } from 'react-bootstrap'
import Logo from "../assets/image.png"
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Stack className='p-4' style={{ width: '100%', maxWidth: '1600px', margin: '0 auto' }}>
      <Stack direction='horizontal' justifyContent='space-between' style={{ justifyContent: 'space-between' }} alignItems='center'>
        {/* if clicked on the logo, redirect to the home page */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Stack direction='horizontal' gap={4} alignItems='center'>
            <img src={Logo} alt='logo' style={{ width: 50, height: 50 }} />
            <Stack direction='horizontal' gap={1}>
              {/* make the font more bold */}
              <h3 className='fw-bold' style={{ fontSize: 20, color: "black" }}>Prairie</h3>
              <h3 className='fw-bold' style={{ fontSize: 20, color: '#115cbd' }}>Jobs</h3>
            </Stack>
          </Stack>
        </Link>
        <Stack direction='horizontal' gap={4} className='justify-content-end'>
          <Link className='text-decoration-none text-black' to="/about">About</Link>
          <Link className='text-decoration-none text-black' to="/FAQ">FAQ</Link>
          <Button style={{ backgroundColor: '#105DBC', color: "#f1edee", fontSize: 18, borderRadius: 5 }} className=' text-white px-4 font-bold' onClick={() => navigate('/login')}>Login</Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Navbar