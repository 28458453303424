import { useState, useEffect } from 'react';
import { Button, Stack } from 'react-bootstrap';
import ManStanding from "../assets/man_standing.png";
import "../App.css";
import SasktelLogo from "../assets/sasktel.png";
import Example1 from "../assets/image-4.png";
import Example2 from "../assets/image-5.png";
import Example3 from "../assets/image-6.png";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const dummyJobs = [
  {
    company: "Sasktel",
    title: "Sales Associate",
    description: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.",
    isNew: true,
    logo: SasktelLogo,
  },
  {
    company: "Sasktel",
    title: "Sales Associate",
    description: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.",
    isNew: true,
    logo: SasktelLogo,
  },
  {
    company: "Sasktel",
    title: "Sales Associate",
    description: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.",
    isNew: true,
    logo: SasktelLogo,
  },
]

const heroData = [
  {
    backgroundImage: Example1,
    heading: "Construction"
  },
  {
    backgroundImage: Example2,
    heading: "Administration"
  },
  {
    backgroundImage: Example3,
    heading: "Industry"
  }
]

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 720);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Stack style={{
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
        display: "grid",
        gridTemplateColumns: isMobile ? "100%" : "50% 50%",
        padding: isMobile ? "20px" : "0",
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto'
      }}>
        <Stack style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "flex-start" : "center",
          justifyContent: "center",
          marginBottom: "20px",
          paddingLeft: isMobile ? "10px" : "20px",
          width: "100%"
        }}>
          <p style={{
            fontSize: isMobile ? "36px" : "60px",
            textAlign: "left",
          }} className='fw-bold'>
            Discover your Next Opportunity
            <span style={{
              color: "#56585b",
              fontSize: isMobile ? "36px" : "60px"
            }} className='fw-bold'>
              {' '}Connect Apply Succeed
            </span>
          </p>
          <div style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            gap: isMobile ? "10px" : "0",
          }}>
            <input style={{
              width: isMobile ? "100%" : "60%",
              height: "60px",
              border: "1px solid #56585b",
              borderRadius: "5px 0 0 5px",
            }} type="text" />
            <Button style={{
              height: "60px",
              width: isMobile ? "100%" : "100px",
              borderRadius: isMobile ? "5px" : "0 5px 5px 0",
              padding: "10px",
              background: "#115CBD"
            }}>Search</Button>
          </div>
        </Stack>
        <Stack style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img src={ManStanding} alt="Man Standing" style={{
            height: isMobile ? "300px" : "500px",
            width: "auto"
          }} />
        </Stack>
      </Stack>
      <div className="shape rect rectangle-de7a03b8b1df"></div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingLeft: isMobile ? "10px" : "20px",
        width: "100%",
        minHeight: "80vh",
        justifyContent: "center",
        gap: "100px",
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto',
        padding: '40px 0'
      }}>
        <p style={{
          fontSize: isMobile ? "24px" : "36px",
          fontWeight: "bold",
          textAlign: "left",
        }}>
          Get the Job that Fits You
        </p>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          width: "100%",
        }}>
          {heroData.map((data, index) => (
            <div key={index} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "360px",
              height: "189px",
              position: "relative",
            }}>
              <img src={data.backgroundImage} alt="Example 1" style={{
                width: "360px",
                height: "189px",
                borderRadius: "5px"
              }} />
              <p style={{
                fontSize: "48px",
                fontWeight: "bold",
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}>
                {data.heading}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* a blue rectangle */}
      <div style={{
        width: "100%",
        height: "100%",
        maxHeight: "500px",
        background: "#105dbc",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "40px",
        flexWrap: "wrap"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start"
        }}>
          <p style={{
            fontSize: "48px",
            // fontFamily: "Roboto",
            fontWeight: "bold",
            color: "white",
          }}>
            Lorem, ipsum.
          </p>
          <p style={{
            textWrap: "wrap",
            color: "white",
            fontSize: "31px",
            // TODO: confirm font family
            // fontFamily: "Roboto",
            alignSelf: "flex-start",
            textAlign: "left",
            maxWidth: "500px",
          }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet quisquam velit expedita est ipsa optio rem nesciunt qui commodi obcaecati?
          </p>
        </div>
        <div>
          <h3 style={{
            fontSize: "48px",
            // fontFamily: "Roboto",
            fontWeight: "bold",
            color: "white",
          }}>Explore Now </h3>
        </div>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: isMobile ? "10px" : "20px",
        minHeight: "100vh",
        paddingTop: "50px",
        paddingBottom: "50px",
        width: "100%",
        maxWidth: '1600px',  // Set the same max-width as other components
        margin: '0 auto'
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%", // Set width to 100% to make it full width of the container
        }}>
          <p style={{
            fontSize: isMobile ? "24px" : "36px",
            fontWeight: "bold",
            textAlign: "left",
            width: "100%", // Set width to 100% to ensure the text stretches across
          }}>
            Featured Jobs
          </p>
          <p style={{
            fontSize: "24px",
            textAlign: "left",
            color: "#8AA29E"
          }}>
            Lorem ipsum dolor sit amet consectetur.
          </p>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            flexWrap: "wrap",
            width: '100%',  // Set width to 100% to align with other components
            maxWidth: '1600px', // Consistent max width
            margin: '0 auto', // Center align within the container
          }}>
            {dummyJobs.map((job, index) => (
              <div key={index} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                padding: "20px",
                borderRadius: "10px",
                // give it a shadow
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
              }}>
                {/* LOGO */}
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                }}>
                  <img src={job.logo} alt="Sasktel Logo" style={{
                    height: "30px",
                    width: "auto",
                    alignSelf: "flex-start",

                  }} />
                  {/* new badge next to logo if it's new */}
                  {job.isNew && (
                    <div style={{
                      color: "#FF7F11",
                      backgroundColor: "rgba(255, 127, 17, 0.2)",
                      padding: "5px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}>
                      {job.isNew ? "New" : ""}
                    </div>
                  )}
                </div>
                {/* JOB TITLE */}
                <p style={{
                  textAlign: "left",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#115CBD",
                  alignSelf: "flex-start",
                }}>
                  {job.title}
                </p>
                {/* JOB DESCRIPTION */}
                <p style={{
                  textWrap: "wrap",
                  maxWidth: "300px",
                  textAlign: "left",
                }}>
                  {job.description}
                </p>
                <Button style={{
                  backgroundColor: "#115CBD",
                  color: "white",
                  borderRadius: "5px",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  alignSelf: "flex-start",
                  height: "40px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>Apply</Button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
