import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import Footer from "../components/Footer";

const CreateUser = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setrole] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);
  const [employerData, setEmployerData] = React.useState([]);
  const [employer, setEmployer] = React.useState("");
  const [assignmentStartDate, setAssignmentStartDate] = React.useState("");
  const [assignmentEndDate, setAssignmentEndDate] = React.useState("");
  const [password, setPassword] = React.useState("");

  async function getEmployers() {
    var res = await axios.get("/api/employers/get");
    console.log(res);
    const { data } = res;
    setEmployerData(data.data);
  }

  useEffect(() => {
    getEmployers();
  }, []);

  const createUser = async (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      role,
      employer,
      assignmentStartDate,
      assignmentEndDate,
    };
    console.log(data);
    try {
      const response = await axios.post("/api/admins/createUser", data);
      console.log(response);
      if (response.status === 201) {
        setMessageSent(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section>
      <Navbar />
      <div className="max-w-[1500px] m-auto min-w-[75%] p-6 h-[90vh] justify-center items-center flex">
        <form action="#" class="space-y-8">
          <div className="flex gap-4 max-w-[1500px] justify-between">
            <div className="w-full">
              <label htmlFor="name">First Name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                id="name"
                class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="John"
                required
              />
            </div>
            <div className="w-full">
              <label htmlFor="name">Last Name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                id="name"
                class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Doe"
                required
              />
            </div>
          </div>
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="name@prairiejobs.ca"
              required
            />
          </div>
          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">
                Password
            </label>
            <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                name="password"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Password"
                required
            />
          </div>
          {/* <div>
            <label
              for="phoneNumber"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Phone number
            </label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="+1 630-496-7833"
              required
            />
          </div> */}
          <select
            value={role}
            onChange={(e) => setrole(e.target.value)}
            id="role"
            name="role"
            className="w-full py-2.5 text-gray-900 px-4 border-gray-300 bg-gray-50 focus:ring-primary-500 focus:border-primary-500 shadow-md"
          >
            <option value="default">Please select a role</option>
            <option value="employee">Employee</option>
            <option value="employer">Employer</option>
            <option value="admin">Admin</option>
          </select>
          {role === "employee" && (
            <div className="flex-col">
              <div class="sm:col-span-2">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Select Employer (optional)
                </label>
                <select
                  value={employer}
                  onChange={(e) => setEmployer(e.target.value)}
                  id="role"
                  name="role"
                  className="w-full py-2.5 text-gray-900 px-4 border-gray-300 bg-gray-50 focus:ring-primary-500 focus:border-primary-500 shadow-md"
                >
                  <option value="default">Please select an employer (optional)</option>
                  {employerData.map((employer) => {
                    return (
                      <option value={employer._id}>
                        {employer.first_name} {employer.last_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <div>
                  <label htmlFor="assignedDate">Assignment Start Date (optional)</label>
                  <input
                    value={assignmentStartDate}
                    onChange={(e) => {
                      setAssignmentStartDate(e.target.value);
                    }}
                    type="date"
                    name="assignedDate"
                    id="assignedDate"
                    class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="John"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="assignedEndDate">Assignment End Date (optional)</label>
                  <input
                    value={assignmentEndDate}
                    onChange={(e) => {
                      setAssignmentEndDate(e.target.value);
                    }}
                    type="date"
                    name="assignedEndDate"
                    id="assignedEndDate"
                    class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="John"
                    required
                  />
                </div>
              </div>
            </div>
          )}
          <button
            onClick={createUser}
            type="submit"
            class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 bg-indigo-600"
          >
            Create User
          </button>
          {messageSent && (
            <div class="text-center">
              <p class="mt-10 text-center text-l text-green-500">
                User created successfully!
              </p>
            </div>
          )}
        </form>
      </div>
      <Footer />
    </section>
  );
};

export default CreateUser;
