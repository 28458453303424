import Navbar from "../components/Navbar"
function Employee() {
  return (
    <>
     <div style={{ height: '90vh' }}>
        <Navbar />
     </div>
    </>
  )
}

export default Employee