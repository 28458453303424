import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "../assets/contact-us.png";

const Contact = () => {

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
      inquiryType: subject
    };
    try {
      const response = await fetch(
        // "https://free-form-rwlo.onrender.com/send",
        "http://localhost:3001/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    console.log(firstName, lastName, email, phoneNumber, message, subject);
  }, [firstName, lastName, email, phoneNumber, message, subject]);


  return (
    <section class="bg-white">
      <Navbar />
      <div className="flex flex-wrap w-[1600px] m-auto p-6">
        {/* <div className="flex items-center justify-center"> */}
        <img
          src={ContactUs}
          alt="cover"
          class="w-auto h-[100vh] mx-auto rounded-md object-cover object-center items-center md:none"
        />
        {/* </div> */}
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-black">
            Contact Us
          </h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-900 sm:text-xl">
            Send us a message and we'll be in touch.
          </p>
          <form action="#" class="space-y-8">
            <div className="flex gap-4 justify-between">
              <div className="w-full">
                <label htmlFor="name">First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  id="name"
                  class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="John"
                  required
                />
              </div>
              <div className="w-full">
                <label htmlFor="name">Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  id="name"
                  class="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Doe"
                  required
                />
              </div>
            </div>
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@prairiejobs.ca"
                required
              />
            </div>
            <div>
              <label
                for="phoneNumber"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Phone number
              </label>
              <input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="+1 630-496-7833"
                required
              />
            </div>
            <select
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              id="subject"
              name="subject"
            className="w-full py-2.5 text-gray-900 px-4 border-gray-300 bg-gray-50 focus:ring-primary-500 focus:border-primary-500 shadow-md">
              <option value="default">Please select an option</option>
              <option value="general">General Inquiry</option>
              <option value="technical">Technical Support</option>
              <option value="feedback">Feedback</option>
              <option value="adding company">Adding Company</option>
            </select>
            <div class="sm:col-span-2">
              <label
                for="message"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                rows="6"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              onClick={handleSubmit}
              type="submit"
              class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 bg-indigo-600"
            >
              Send message
            </button>
          </form>
          {messageSent && (
            <div class="mt-8">
              <p class="text-lg text-center text-green-500">
                Your message has been sent successfully!
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer/>
    </section>
  );
};

export default Contact;
