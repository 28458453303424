import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/Home';
import Test from './pages/Test';
import Footer from './components/Footer';
import Admin from './pages/admin/Admin';
import TimeCard from './pages/admin/TimeCard';
import UserList from './pages/admin/UserList';
import Employee from './pages/Employee';
import Login from './pages/Login';
import { Auth0Provider } from '@auth0/auth0-react';
import Contact from './pages/Contact';
import { useContext, createContext, useState, useEffect } from 'react';
import CreateUser from './pages/CreateUser';

export const ContextProvider = createContext();
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
    }

  }, [])

  return (
    <BrowserRouter>
    <ContextProvider.Provider value={{user, setUser}}>
    <Auth0Provider
      domain="dev-94097o85.us.auth0.com"
      clientId="Q4u8ZDm5UOEiVkmPHkM7jahDRl5CarrD"
      redirectUri={window.location.origin}
      >
      <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/test' element={<Test />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/time' element={<TimeCard />} />
      <Route path='/user-list' element={<UserList />} />
      <Route path='/login' element={<Login />} />
      <Route path='/contact' element={<Contact />} />
      <Route  path='/employee' element={<Employee />}/>
      <Route path='/create-user' element={<CreateUser />} />
      </Routes>
      </Auth0Provider>
      </ContextProvider.Provider>
    </BrowserRouter>
  );
}

export default App;
