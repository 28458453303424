import React from 'react'
import Logo from "../assets/image.png"
import { Stack } from 'react-bootstrap'

const Footer = () => {
    return (
        <Stack direction='horizontal' gap={4} alignItems='center' style={{ padding: "20px", justifyContent: "space-between", backgroundColor: '#262626', color: 'white', height: 161}}>
            <Stack direction='horizontal' gap={4} alignItems='center'>
                <img src={Logo} alt='logo' style={{ width: 50, height: 50 }} />
                <Stack direction='horizontal' gap={1}>
                    {/* make the font more bold */}
                    <h3 className='fw-bold' style={{ fontSize: 20 }}>Prairie</h3>
                    <h3 className='fw-bold' style={{ fontSize: 20, color: '#115cbd' }}>Jobs</h3>
                </Stack>
            </Stack>
            <div>
                <div direction='vertical' gap={4} alignItems='center'>
                    <h3 style={{
                        cursor: "pointer",
                        fontSize: 17,
                        fontWeight: "500"
                    }}>© Prairie Jobs</h3>
                </div>
            </div>
            <div direction='vertical' gap={4} alignItems='center'>
                <h3 style={{
                    cursor: "pointer",
                    fontSize: 17,
                    fontWeight: "500"
                }}>About</h3>
                <h3 style={{
                    cursor: "pointer",
                    fontSize: 17,
                    fontWeight: "500"
                }}>FAQ</h3>
                <h3 style={{
                    cursor: "pointer",
                    fontSize: 17,
                    fontWeight: "500"
                }}>Contact</h3>
            </div>
        </Stack>
    )
}

export default Footer
